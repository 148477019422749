document
  .querySelectorAll(".navbar-content, .footer-content img")
  .forEach((item) => {
    item.addEventListener("click", () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  });

document.addEventListener("DOMContentLoaded", function () {
  const video = document.getElementById("demo-video");
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };

  function handleIntersect(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        video.play();
      } else {
        video.pause();
      }
    });
  }

  const observer = new IntersectionObserver(handleIntersect, options);
  observer.observe(video);
});

console.log("Welcome to My Awesome Landing Page!");
